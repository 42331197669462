.FilterButtons {
    text-align: left;
    margin-bottom: 10px;
    font-size: 12px;
}

@media only screen and (min-width: 480px) {
    .FilterButtons {
        text-align: left;
        margin-bottom: 10px;
        font-size: 14px;
    }

    .FilterButton {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 5px;
        text-transform: uppercase;
        background-color: #C7C7C7;
        border-radius: 5px;
        padding-left: 10px;
        padding-right: 10px;
        color: white;
        cursor: pointer;
        font-weight: 700;
    }
}

.FilterButtons .Large {
    font-size: 24px;
}

.FilterButton {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 5px;
    text-transform: uppercase;
    background-color: #C7C7C7;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    cursor: pointer;
    font-weight: 700;
}

.FilterButton:hover {
    background-color: #F77754;
    opacity: .5;
}

.FilterButton.Selected {
    background-color: #F77754;
}

.FilterButton.Selected:hover {
    background-color: #F77754;
    opacity: 1;
}
