@media all and (min-width: 480px) {
    .Login {
        padding: 60px 0;
    }

    .Login form {
        margin: 0 auto;
        max-width: 320px;
    }
}

.Login {
    text-align: center;
}

.Login .Notice {
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 700;
}

.Login p {
    margin-top: 15px;
    color: #3E4149;
    font-size: 12px;
}
