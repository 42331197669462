.Lander {
    text-align: center;

    -webkit-animation: fadeInUp 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUp 1s; /* Firefox < 16 */
    -ms-animation: fadeInUp 1s; /* Internet Explorer */
    -o-animation: fadeInUp 1s; /* Opera < 12.1 */
    animation: fadeInUp 1s;
}

.Lander .Headline {
    margin: 0 auto;
    margin-bottom: 20px;
}

.Lander .MainWelcome {
    margin-top: 50px;
    text-align: center;
    display: inline-block;
}

.Lander .MainWelcome h1 {
    font-size: 36px;
}

.Lander .MainWelcome h2 {
    font-size: 24px;
    text-align: center;
    color: #808080;
    margin-bottom: 25px;
}

.Lander .MainWelcome h3 {
    font-size: 18px;
    text-align: center;
    color: black;
    margin-bottom: 0px;
    margin-top: 8px;
}

.Lander .MainWelcome p {
    margin-bottom: 0px;
}

.Lander p {
    margin-bottom: 10px;
    font-size: 16px;
    color: #787878;
}

.Lander .MainWelcome .EmailSubscribe {
    margin-top: 10px;
}

.Lander .MainWelcome .EmailSubscribe input {
    font-size: 24px;
    border-radius: 5px;
    border: 1px solid #303239;
    width: 300px;
    padding-left: 8px;
    color: black;
}

.Lander .MainWelcome .EmailSubscribe button {
    font-size: 24px;
    font-weight: 700;
    background-color: #F77754;
    color: white;
    border-radius: 5px;
    border: 1px solid #F77754;
    margin-left: 8px;
}

.Lander .MainWelcome .EmailSubscribe .msg-alert {
    margin-top: 5px;
    font-weight: 700;
}

.Lander .ExampleDeals {
    text-align: center;
}

.Home .ExampleDeals .DealsRow .AirfareDeal {
    display: inline-block;
}

.Lander .HowDoesItWork {
    text-align: center;
}

.Lander .HowDoesItWorkRow {
    margin-top: 30px;
}

.Lander .HowDoesItWork p {
    margin-top: 10px;
    font-size: 18px;
    color: #787878;
}

.Lander .Testimonials {
    display: inline-block;
}

.Lander .FAQ {
    display: inline-block;
}

.Lander .ComingSoon {
    display: inline-block;
}


.Lander .Section .Search {
    margin-bottom: 20px;
}

.Lander .Section h1 {
    margin-top: 10px;
    font-size: 28px;
    color: black;
}

.Lander .Section h2 {
    text-align: center;
    font-size: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    background-color: rgba(247, 119, 84, .8);
    border-radius: 5px;
    display: inline-block;
    color: white;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.Lander .Section h2:hover {
    background-color: rgba(247, 119, 84, 1);
}

.Lander .Section p {
    font-size: 18px;
    color: #787878;
    margin-bottom: 10px;
}

.Lander .Section h3 {
    text-align: center;
    font-size: 21px;
    font-weight: 400;
    color: #787878;
    margin-top: 30px;
    margin-bottom: 20px;
}

.Lander .Product .Entry {
    border-radius: 10px;
    padding: 0px;
    margin-bottom: 25px;
}

.Lander .Product h1 {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    display: inline-block;
    border-radius: 5px;
    color: black;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.Lander .Product h1:hover {
}

.Lander .Product .Entry p {
    font-size: 21px;
    color: #787878;
}

.Lander .ComingSoon .Features {
    text-align: center;
}

.Lander .ComingSoon .Features h1 {
    font-size: 24px;
    font-weight: 700;
    text-transform: lowercase;
    margin-top: 0px;
}

.Lander .HowDoesItWork .Image {
    margin-bottom: 10px;
}

.Lander .HowDoesItWorkRow {
    display: flex;
}

.Lander .Column {
    flex: 1; /*grow*/
    margin-left: 20px;
    margin-right: 20px;
}

.Lander .HowDoesItWork .Number {
    background-color: rgba(247, 119, 84, .8);
    display: inline-block;
    border-radius: 5px;
    color: white;
    padding-left: 15px;
    padding-right: 15px;

    font-size: 36px;
    margin-bottom: 12px;
}

.Lander .HowDoesItWork .Number:hover {
    background-color: rgba(247, 119, 84, 1);
}

.Lander .HowDoesItWork .Text {
    margin-bottom: 10px;
    font-size: 24px;
}

.Lander .SignUpButton {
    background-color: #F6F6F6;
    font-size: 18px;
    color: rgb(247, 119, 84, 1);
    border: 1px solid transparent;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.Lander .SignUpButton:hover {
    border: 1px solid rgb(247, 119, 84, 1);
}

.Lander .SignUpButton .Button {
    background-color: rgb(247, 119, 84, 1);
    font-size: 24px;
    padding-left: 5px;
    padding-right: 5px;
    color: white;
    border-radius: 5px;
    margin-right: 15px;
    text-transform: uppercase;
    border: 1px solid transparent;
    transition: border 2s;
    margin-bottom: 5px;
}

@media only screen and (min-width: 480px) {
    .Lander .SignUpButton {
        background-color: #F6F6F6;
        font-size: 24px;
        display: inline-block;
        padding-left: 16px;
        padding-right: 16px;
        color: rgb(247, 119, 84, 1);
        border-radius: 5px;
        padding-top: 15px;
        padding-bottom: 15px;
        width: auto;
    }

    .Lander .SignUpButton .Button {
        display: inline-block;
        background-color: rgb(247, 119, 84, 1);
        font-size: 24px;
        padding-left: 8px;
        padding-right: 8px;
        color: white;
        border-radius: 5px;
        margin-right: 15px;
        text-transform: uppercase;
        border: 1px solid transparent;
        transition: border 2s;
    }

    .Lander .Section h2 {
        display: inline-block;
    }
}

.Lander .SignUp .SubText {
    margin-top: 10px;
}

.Lander .SignUp .Text {
    display: inline-block;
}


@media (max-width: 768px) {
    /*breakpoint*/
    .HowDoesItWorkRow {
        flex-direction: column;
    }
}

