.Flights {
}

.Flights .SelectedDeal {
    margin-bottom: 15px;
}

.Flights .Filters {
    margin-bottom: 20px;
}

.Flights h2 {
    background-color: #303239;
    color: white;
    border-radius: 5px;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 4px;
    padding-top: 4px;
    font-size: 28px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 5px;
    opacity: .9;
}

.Flights h3 {
    margin-top: 10px;
}

.Flights .Clear {
    text-align: center;
    font-size: 18px;
    border-radius: 5px;
    background-color: white;
    color: #3E4149;
    cursor: pointer;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 2px;
    padding-top: 2px;
    border: 1px solid #3E4149;
    width: 60px;
    margin-bottom: 10px;
}

.Flights .Clear:hover {
    background-color: #3E4149;
    color: white;
}

.Flights .AirfareDetails {
    margin-top: 20px;
    text-align: center;
    margin: auto;
}

.Flights {
    -webkit-animation: fadeInUp 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUp 1s; /* Firefox < 16 */
    -ms-animation: fadeInUp 1s; /* Internet Explorer */
    -o-animation: fadeInUp 1s; /* Opera < 12.1 */
    animation: fadeInUp 1s;
}

@media only screen and (min-width: 480px) {
    .AirfareDetail {
        display: inline-block;
    }

    .AirfareDetail .Price {
        width: 200px;
        font-size: 54px;
    }

    .Flights .SelectedDeal {
        margin-bottom: 30px;
    }

    .Flights .Filters {
        margin-bottom: 30px;
    }
}
