.App {
    margin-top: 5px;
}

.App .container {
    padding-left: 10px;
    padding-right: 10px;
    height: calc(100% - 40px);
}

.container-fluid {
    padding: 0px;
}

.App .navbar-right {
    font-weight: 700;
}

.App .navbar-right li a {
    color: #acacac;
}

.navbar-default .navbar-nav > a {
    color: #3E4149;
}

.navbar-default .navbar-nav > .active > a {
    color: #3E4149;
    font-weight: 700;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
    background-color: transparent;
}

.App .navbar-brand {
    font-weight: 700;
}

.App .navbar-default {
    border: 0px;
    background-color: white;
}

.App .logo {
    background-color: rgb(62, 65, 73, 1);
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 5px;
    color: white;
    font-size: 16px;
}

.App .sub {
    color: #FFB961;
}

.App .logo:hover {
    background-color: rgb(62, 65, 73, .9);
}

@media only scsreen and (min-width: 480px) {
    .App {
        margin-top: 15px;
    }

    .container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
}
