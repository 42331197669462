.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
    font-size: 18px;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}

.LoaderButton {
    border: 1px solid black;
    font-weight: 700;
    font-size: 18px;
    color: black;
    padding-top: 5px;
    padding-bottom: 5px;
}
