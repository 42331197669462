.Headline {
    text-align: center;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: rgb(48, 50, 57, 1);
    display: table;
    padding-left: 15px;
    padding-right: 15px;
    color: #fdab4f;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 26px;
    font-weight: 700;
    opacity: 1
}

.Headline:hover {
    background-color: rgb(62, 65, 73, .9);
}
