.FAQEntry {
    margin-bottom: 10px;
    width: 100%;
}

.FAQEntry .Question {
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    border: 1px solid #C7C7C7;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}

.FAQEntry .Question:hover {
    background-color: #f5f5f5;
}

.FAQEntry .Answer {
    font-size: 16px;
    font-weight: 200;
    padding-left: 6px;
    padding-right: 6px;
}
