.AirfareDeal {
    cursor: pointer;
    position: relative;
    margin-bottom: 5px;
    margin-right: 5px;
    width: 100%;
}

.AirfareDeal .ImageDiv span {
    width: 100%;
    padding-top: 65%
}

.AirfareDeal .Image {
    width: 100%;
    z-index: -100;
    filter: brightness(70%);
}

.AirfareDeal .Image.Hovered {
    filter: brightness(50%);
}

.AirfareDeal .LoadingImage {
    width: 100%;
    z-index: -100;
}

.AirfareDeal .DealText {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AirfareDeal .CityName {
    font-size: 24px;
    font-weight: 700;
    color: white;
    z-index: 100;
    top: 0;
    text-align: center;
}

.AirfareDeal .Route {
    font-size: 22px;
    font-weight: 700;
    color: white;
    z-index: 100;
    top: 0;
    text-align: center;
}

.AirfareDeal .Flag {
    font-size: 36px;
    color: white;
    z-index: 100;
    position: absolute;
    left: 0px;
    width: 100%;
    text-align: left;
}

.AirfareDeal .Price {
    color: #fdab4f;
    z-index: 100;
    position: absolute;
    display: inline-block;
    top: 8px;
    right: 5px;
    text-align: right;
    margin-right: 5px;
}

.AirfareDeal .Price span {
    display: table;
    margin-bottom: 2px;
}

.AirfareDeal .Price .AsLowAsPrice {
    font-size: 38px;
    line-height: 38px;
    font-weight: 700;

}

.AirfareDeal .Price .Savings {
    margin-top: 0px;
    font-size: 14px;
    font-weight: 700;
    color: white;
    text-decoration: line-through;
}

.AirfareDeal .TripInfo {
    z-index: 100;
    position: absolute;
    display: inline-block;
    top: 8px;
    left: 8px;
    text-align: left;
}

.AirfareDeal .TripInfo .TimeOffered {
    font-size: 16px;
    background-color: #F77754;
    font-weight: 700;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
    margin-bottom: 5px;
    display: table;
}

.AirfareDeal .TripInfo .TripLengthsOffered {
    font-size: 12px;
    background-color: rgb(148, 177, 189);
    display: table;
    font-weight: 700;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
    margin-bottom: 2px;
}

.AirfareDeal .TripInfo .NumDeals {
    font-size: 12px;
    font-weight: 700;
    background-color: #3E4149;
    color: #fdab4f;
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 3px;
    border: 1px solid #fdab4f;
}

.AirfareDeal .LastUpdated {
    z-index: 100;
    position: absolute;
    font-size: 12px;
    bottom: 5px;
    right: 8px;
    text-align: right;
    color: white;
}

.AirfareDeal .Options {
    z-index: 100;
    position: absolute;
    font-size: 12px;
    bottom: 5px;
    left: 8px;
    text-align: left;
    color: white;
}


@media only screen and (min-width: 700px) {
    .AirfareDeal {
        display: inline-block;
        width: calc(50% - 5px);
        margin-bottom: 5px;
    }

    .AirfareDeal .Image {
        /*if you want to preserve aspect ratio*/
        /*width: auto;*/
        /*max-width: 300px;*/
        /*height: auto;*/
        /*max-height: 200px;*/
    }
}
