.Plan {
    display: inline-block;
    border: 1px solid black;
    border-radius: 5px;
    width: calc(50% - 10px);
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.Plan:hover {
    border: 1px solid #F77754;
}

.Plan {
    text-align: center;
}

.Plan .PlanName {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 5px;
}

.Plan .Price {
    font-size: 42px;
    text-transform: uppercase;
    font-weight: 700;
}

.Plan .PerUnit {
    font-size: 14px;
    margin-bottom: 5px;
}

.Plan.Selected {
    border: 3px solid #F77754;
}
