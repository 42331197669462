.Home {
    text-align: center;

    -webkit-animation: fadeInUp 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUp 1s; /* Firefox < 16 */
    -ms-animation: fadeInUp 1s; /* Internet Explorer */
    -o-animation: fadeInUp 1s; /* Opera < 12.1 */
    animation: fadeInUp 1s;
}

.Home .FinishProfile {
    margin-top: 60px;
}

.Home .FinishProfile h1 {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
}

.Home .UpgradeProfile {
    margin-top: 60px;
}

.Home .UpgradeProfile h1 {
    font-size: 34px;
    font-weight: 700;
    text-align: center;
}

.Home h1, h2, h3 {
    text-align: left;
}

.Home h3 {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 700;
}

.Home .lander {
    padding: 80px 0;
    text-align: center;
}

.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #999;
}

.Home .Header {
    margin-bottom: 30px;
}

.Home .Filters {
    margin-bottom: 20px;
}

.Home .DealsBoard {
    margin-top: 10px;
    text-align: center;

    -webkit-animation: fadeInUp 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUp 1s; /* Firefox < 16 */
    -ms-animation: fadeInUp 1s; /* Internet Explorer */
    -o-animation: fadeInUp 1s; /* Opera < 12.1 */
    animation: fadeInUp 1s;
}

.Home .DealsBoard .DealsRow {
    width: 100%;
}

.Home .DealsBoard .DealsRow .AirfareDeal {
    display: inline-block;
}

@keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}


@media only screen and (min-width: 480px) {
    .Home {
        margin-left: 15px;
        margin-right: 15px;
    }

    .Home .Header {
        margin-bottom: 30px;
    }

    .Home .DealsBoard {
        margin-top: 35px;
    }
}
