.LoadScreen {
    margin-top: 100px;
    width: 100%;
    text-align: center;
}

.LoadScreen .Loader {
    display: inline-block;
}

.LoadScreen h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
}
