.AirfareDetail {
    overflow: hidden;
    margin-bottom: 5px;
    cursor: pointer;
    width: auto;
}

.AirfareDetail .Price {
    height: 70px;
    width: calc(40% - 5px);
    line-height: 70px;
    background-color: #3E4149;
    color: #FFB961;
    font-size: 40px;
    font-weight: 700;
    border-radius: 5px;
    float: left;
    opacity: 1;
}

.AirfareDetail .Price.Hovered {
    opacity: .9;
}

.AirfareDetail .DatesInfo {
    width: calc(27% - 5px);
    height: 70px;
    float: left;
    margin-left: 5px;
    color: #537D91;
}

.AirfareDetail .TripInfo {
    width: calc(27% - 2px);
    height: 70px;
    float: left;
    margin-left: 5px;
}

.AirfareDetail .Link {
    float: left;
    width: 5%;
    height: 70px;
    line-height: 70px;
    border: 1px solid #bababa;
    border-radius: 5px;
    color: #787878;
    font-size: 8px;
    font-weight: 600;
    margin-left: 5px;
}

.AirfareDetail .Link.Hovered {
    background-color: #F77754;
    opacity: .5;
    color: white;
}

.AirfareDetail .DatesInfo .TripLength {
    background-color: rgb(164, 209, 200, 1);
    color: white;
    width: 100%;
    border-radius: 5px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
}

.AirfareDetail .DatesInfo .Dates {
    height: 45px;
    width: 100%;
    text-align: center;
    top: 0px;
    position: relative;
}

.AirfareDetail .DatesInfo .Dates .StartDate {
    display: inline-block;
    width: calc(50% - 3px);
    height: 45px;
    background-color: rgb(164, 209, 200, .4);
    border-radius: 5px;
    position: absolute;
    left: 0;
}

.AirfareDetail .DatesInfo .Dates .EndDate {
    display: inline-block;
    margin-left: 6px;
    width: calc(50% - 3px);
    height: 45px;
    background-color: rgb(164, 209, 200, .4);
    border-radius: 5px;
    position: absolute;
    right: 0;
}

.AirfareDetail .DatesInfo .Dates .Day {
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    height: 30px;
    line-height: 30px;
    margin-bottom: -3px;
}

.AirfareDetail .DatesInfo .Dates .Date {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    height: 15px;
    line-height: 15px;
}

.AirfareDetail .TripInfo .Airlines {
    height: 30px;
    width: 100%;
    line-height: 30px;
    margin-bottom: 5px;
    background-color: #537D91;
    border-radius: 5px;
    color: white;
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
}

.AirfareDetail .TripInfo .StopsLayovers {
    height: 35px;
    width: 100%;
    line-height: 35px;
    font-weight: 600;
    background-color: rgb(83, 125, 145, .5);
    border-radius: 5px;
    color: white;
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
}

@media only screen and (min-width: 480px) {
    .AirfareDetail {
        display: inline-block;
    }

    .AirfareDetail .Price {
        width: 200px;
        font-size: 54px;
    }

    .AirfareDetail .DatesInfo {
        width: 130px;
    }

    .AirfareDetail .TripInfo {
        width: 200px;
    }

    .AirfareDetail .Link {
        width: 35px;
    }

    .AirfareDetail .DatesInfo .Dates .Day {
        font-size: 16px;
    }

    .AirfareDetail .DatesInfo .Dates .Date {
        font-size: 14px;
    }

    .AirfareDetail .TripInfo .Airlines {
        font-size: 16px;
    }

    .AirfareDetail .TripInfo .StopsLayovers {
        font-size: 16px;
    }
}
