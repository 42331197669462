.Profile {
    text-align: left;
}

.Profile .form-group {
    margin-top: 20px;
}

.Profile .help-block {
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 8px;
}

.Profile .AccountType {
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 5px;
}

.Profile .Expiry {
    display: inline-block;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 600;
}

.Profile .FreeTrial {
    background-color: #A4D1C8;
    color: white;
    display: inline-block;
    font-weight: 700;
    font-size: 21px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
    text-transform: uppercase;
}

.Profile .Premium {
    background-color: #FFB961;
    color: white;
    display: inline-block;
    font-weight: 700;
    font-size: 21px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
    text-transform: uppercase;
}

.Profile .Billing {
    background-color: #F77754;
    color: white;
    display: inline-block;
    font-weight: 700;
    font-size: 21px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
    text-transform: uppercase;
    margin-left: 8px;
}

.Profile .Policy {
    font-size: 14px;
    margin-top: 5px;
}
