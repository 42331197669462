.Footer {
    margin-top: 30px;
    background-color: rgb(246, 246, 246);
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0;
    height: 60px;
    line-height: 60px;
}

.Footer h1 {
    font-size: 16px;
    color: #787878;
    font-weight: 700;
    margin-top: 0px;
    display: inline-block;
    padding-right: 10px;


}

.Footer h1:hover {
    color: #f77754;
    cursor: pointer;
}
