@media all and (min-width: 480px) {
    .Upgrade {
        padding: 60px 0;
    }

    .Upgrade form {
        margin: 0 auto;
        max-width: 480px;
    }
}

.Upgrade h1 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.Upgrade .Plans {
    max-width: 480px;
    margin: 0 auto;
    margin-bottom: 20px;
}
