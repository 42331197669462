.ActionButton {
    font-weight: 700;
    border-radius: 5px;
    color: #F77754;
    background-color: #F7775433;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    margin-bottom: 15px;
    border: 1px solid #F77754;
}

.ActionButton:hover {
    background-color: #F77754;
    color: white;
}
