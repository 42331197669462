.SupportedAirports {

}

.SupportedAirports p {
    font-size: 21px;
    margin-bottom: 15px;
}

.SupportedAirports .City {
    font-size: 32px;
    margin-bottom: 5px;
    font-weight: 700;
}

.SupportedAirports .City .Airport {
    display: inline-block;
    margin-right: 5px;
}

.SupportedAirports .City .Code {
    display: inline-block;
    font-size: 16px;
    color: #787878;
}