.FAQ {
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
}

@media only screen and (min-width: 480px) {
    .FAQ {
        width: 480px;
    }
}
