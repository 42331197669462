.ShareButton {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 5px;
    display: table;
    cursor: pointer;
    text-transform: uppercase;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-bottom: 10px;
    color: #fdab4f;
    background-color: #3E4149;
    opacity: .8;
}

.ShareButton:hover {
    opacity: .9;
}

.ShareButtonClicked {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 5px;
    display: table;
    cursor: pointer;
    text-transform: uppercase;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-bottom: 10px;
    color: #fdab4f;
    background-color: #3E4149;
    opacity: 1;
}
